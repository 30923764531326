import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="header">
      <div className="logo-section">
        <img src="/sebatian-logo.png" alt="Sebatian Logo" className="logo" />
        <span className="logo-text">Sebatian Validator</span>
      </div>

      <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
        <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
        <Link to="/projects" onClick={() => setMenuOpen(false)}>Projects</Link>
        <a 
          href="https://sebatian.substack.com/" 
          target="_blank" 
          rel="noopener noreferrer"
          onClick={() => setMenuOpen(false)}
        >
          Blog
        </a>
      </nav>

      <div className="menu-toggle">
        <button onClick={() => setMenuOpen(!menuOpen)} className="menu-icon">
          <FaBars />
        </button>
      </div>
    </header>
  );
};

export default Header;
