import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <div className="video-container">
      <video autoPlay loop muted playsInline id="background-video">
        <source src="/videos/background.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>

    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
